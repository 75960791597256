import React from 'react';
import Loadable from 'react-loadable';

import LoadingPlaceholder from '../components/atoms/LoadingPlaceholder/LoadingPlaceholder';

export const HomePage = Loadable({
	loader: () => import('../components/pages/Home'),
	loading: () => <LoadingPlaceholder />,
});

export const SearchPage = Loadable({
	loader: () => import('../components/pages/Search'),
	loading: () => <LoadingPlaceholder />,
});

export const VenuePage = Loadable({
	loader: () => import('../components/pages/Venue'),
	loading: () => <LoadingPlaceholder />,
});

export const AdminPage = Loadable({
	loader: () => import('../components/pages/Admin'),
	loading: () => <LoadingPlaceholder />,
});

export const AboutPage = Loadable({
	loader: () => import('../components/pages/About'),
	loading: () => <LoadingPlaceholder />,
});

export const BookmarksPage = Loadable({
	loader: () => import('../components/pages/Bookmarks'),
	loading: () => <LoadingPlaceholder />,
});
