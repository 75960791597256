export default {
	colors: {
		primary: '#2763E2',
		darkPrimary: '#1346B6',
		white: '#ffffff',
		black: '#000000',
		base: '#F5F7FA',
		dark: '#233040',
		red: '#EF4134',
	},
	spacing: {
		padding: '2rem',
	},
	flexboxgrid: {
		// Defaults
		gridSize: 12, // rem
		gutterWidth: 1, // rem
		outerMargin: 2, // rem
		mediaQuery: 'only screen',
		container: {
			sm: 46, // rem
			md: 60, // rem
			lg: 65, // rem
		},
		breakpoints: {
			xs: 0, // em
			sm: 48, // em
			md: 64, // em
			lg: 75, // em
		},
	},
	maps: {
		style: `element:geometry%7Ccolor:0xf5f5f5&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x616161&style=element:labels.text.stroke%7Ccolor:0xf5f5f5&style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:road%7Celement:geometry%7Ccolor:0xffffff&style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:road.highway%7Celement:geometry%7Ccolor:0xdadada&style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:transit.line%7Celement:geometry%7Ccolor:0xe5e5e5&style=feature:transit.station%7Celement:geometry%7Ccolor:0xeeeeee&style=feature:water%7Celement:geometry%7Ccolor:0xc9c9c9&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e`,
	},
	keys: {
		maps: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
	},
};
