import '@firebase/firestore';
import '@firebase/auth';

import firebase from '@firebase/app';
import { ThemeProvider } from 'emotion-theming';
import React from 'react';
import { FirestoreProvider } from 'react-firestore';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import GAListener from './components/modules/GAListener/GAListener';
import { firebaseConfig } from './config';
import {
	AboutPage,
	AdminPage,
	BookmarksPage,
	HomePage,
	SearchPage,
	VenuePage,
} from './routes/Routes';
import theme from './theme';
import { Takeover } from './Takeover';

// Setup Firebase things.
firebase.initializeApp(firebaseConfig);
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

class Wrapper extends React.Component {
  constructor() {
    super();

    this.localStorageKey = '_fpCanAccess';
    this.password = '2gr&QZ69F&VFlQO814';

    this.state = { canAccess: localStorage.getItem(this.localStorageKey) === 'true' };

    this.attemptUnlock = this.attemptUnlock.bind(this);
  }

  attemptUnlock(passwordAttempt) {
    if (passwordAttempt === this.password) {
      this.setState({ canAccess: true }, () => {
        localStorage.setItem(this.localStorageKey, 'true');
      });
    }
  }

  render() {
    return (
      <FirestoreProvider firebase={firebase} useTimestampsInSnapshots>
        <ThemeProvider theme={theme}>
          {this.state.canAccess ? (
            <Router>
              <GAListener>
                <Route component={HomePage} exact path="/" />
                <Route
                  path="/search"
                  render={(props) => (
                    <SearchPage {...props} key={props.location.search} />
                  )}
                />
                <Route
                  path="/venue/:venue"
                  render={(props) => <VenuePage {...props} />}
                />
                <Route component={AboutPage} path="/about" />
                <Route component={AdminPage} path="/admin" />
                <Route component={BookmarksPage} path="/bookmarks" />
              </GAListener>
            </Router>
          ) : <Takeover attemptUnlock={this.attemptUnlock} />}
        </ThemeProvider>
      </FirestoreProvider>
    );
  }
}

export default Wrapper;
