export const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY || '',
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || '',
	mapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || '',
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
	appId: process.env.REACT_APP_FIREBASE_PROJECT_ID || '',
};

export const exceptions = [
	'ChIJd0vKyZNA1GoRBE9qj1v0Ji4',
	'ChIJj9ey57VC1moRx_P321A9kTo',
	'ChIJVRRREVVVVQURgspsi7xzM0k',
	'ChIJgyk42-JC1moR-Inicvd1yOM',
	'ChIJd40SJBxD1moRk4G8A4d6ZYg',
	'ChIJH14sIVFMzWoRbkfOR6cfZL8',
	'ChIJl6Uv54rD1WoR68a5LC-2LpE',
	'ChIJxQorG-lm1moRYgINo7pxA3U',
	'ChIJaeatovVd1moRjsgS03YxfMs',
	'ChIJfU1K8lhD1moRm6I3mLt4x3E',
];

export const excluded = [
	"grill'd",
	'casino',
	'dominos',
	'pizza hut',
	'aldi',
	'bing boy',
	'boost juice',
	'bucking bull',
	'burger urge',
	'burrito bar',
	'chicken treat',
	'chooks fresh',
	'coles',
	'craveable brands',
	'donut king',
	'eagle boys',
	'fast eddy',
	'guzman',
	'cafe de wheels',
	'hungry jack',
	'jack in the box',
	'kfc',
	'kentucky fried',
	'lord of the fries',
	'mad mex',
	'mcdonald',
	"nando's",
	'noodle box',
	'oporto',
	'pie face',
	'red rooster',
	"salsa's",
	'schnitz',
	'spudbar',
	'subway',
	'sumo salad',
	"uncle tony's kebab",
	'woolworths',
	'zambrero',
	'fetus can beat',
	'Launceston Casino - Country Club',
	'Wrest Point Hotel Casino',
	'Adelaide Casino',
	'Lasseters Hotel Casino',
	'Pullman Reef Hotel Casino',
	'Mindil Beach Casino Resort',
	'Treasury Casino & Hotel',
	'The Ville Resort-Casino',
	'The Star Gold Coast',
	'Crown Melbourne',
	'Crown Perth',
	'Crown Sydney',
	'Avenues Hotel',
	'Bayview Hotel',
	'Bh Community Club',
	'Barmera & Monash Football Club',
	'Barossa Brauhaus Hotel',
	'Valley Inn Hotel',
	'Bridgeway Hotel',
	'Cadell Club',
	'Challa Gardens Hotel',
	'Clovercrest Hotel Motel',
	'COBDOGLA CLUB',
	'Colonnades Tavern',
	'Coobowie Hotel',
	'Cove Tavern',
	'Edinburgh Hotel ',
	'Elizabeth Tavern',
	'Enfield Hotel-Alh',
	'RSL Elizabeth',
	'Exeter Hotel Semaphore',
	'Hotel Eyre ',
	'Federal Hotel Semaphore',
	'German Arms Hotel',
	'Glynde Hotel',
	'Stockade Tavern',
	'Hackney Hotel',
	'Hampstead Hotel',
	'Hyde Park Tavern',
	'Kingsford Hotel',
	'Kent Town Hotel',
	'Lonsdale Hotel',
	'Largs Pier Hotel',
	'Maid And Magpie Hotel',
	'Marion Hotel',
	'Moonta Hotel',
	'Emu Hotel',
	'North Adelaide Hotel',
	'O.G. Hotel',
	'Pastoral Hotel',
	'Payneham Tavern',
	'Para Hills Community Club',
	'Port Noarlunga Hotel',
	'Rose and Crown Hotel',
	'Renmark Hotel',
	'Risdon Hotel',
	'Settlers Tavern',
	'Somerset Hotel ',
	'Spencer Hotel ',
	'Tanunda Hotel',
	'Tea Tree Gully Golf Club',
	'The Gully Public House',
	'Hotel Victor',
	"Victoria Htl O'Halloran Hill",
	'Warradale Hotel',
	'Waterloo Hotel',
	'Whitehorse Inn',
	'Westland Hotel/Motel',
	'Woodcroft Tavern',
	'Strathmore Hotel',
	'Slug & Lettuce Tavern',
	'Royal Oak ',
	'Findon Hotel',
	'Hotel Royal & Cellars',
	'The Alma Tavern',
	'Earl of Leicester Hotel',
	'Yankalilla Hotel',
	'Crown Inn',
	'Auchendarroch House & Tavern',
	'Woolshed On Hindley',
	'Albion Hotel',
	'Aldinga Hotel',
	'Archer Hotel',
	'Ceduna Foreshore Hotel',
	'Federal Hotel',
	'Halfway Hotel',
	'Hendon Hotel',
	'Henley Beach Hotel',
	'Highbury Hotel',
	'Links Hotel/Motel',
	'Cavan Hotel',
	'Commercial Hotel Mt Gambier',
	'New York Bar & Grill',
	'Normanville Hotel',
	'Commercial Hotel Naracoorte',
	'Rex Hotel',
	'Ramsgate Hotel',
	'Seacliff Hotel',
	'Jens Town Hall Hotel',
	'Hilton Hotel',
	'The Highway',
	'Kincraig Hotel',
	'Brompton Hotel',
	'Buckingham Arms Hotel',
	'Mile End Hotel',
	'Mt Gambier Hotel',
	'Oxford Hotel',
	'Park Hotel',
	'Regency Tavern',
	'The Adelaide Club',
	'Clare Country Club',
	'Rosewater Hotel',
	'Sailmaster Tavern',
	'Saturno Group',
	'Semaphore Palais',
	'South Eastern Hotel',
	'Stirling Hotel',
	'Sussex Hotel',
	'The Unley',
	'Watermark Hotel',
	'West End Tavern',
	'West Thebarton Hotel',
	'Western Tavern',
	'German Club',
	'Globe Derby Club',
	'Northgate Community & Sports Club',
	'Queen Adelaide Club',
	'Royal Adelaide Golf Club',
	'Salisbury North Football Club Inc',
	'Salisbury Rsl Sub-Branch',
	'British Hotel',
	'Bridgeport Hotel',
	'Broadway Hotel',
	'Caledonian Hotel',
	'Corio Hotel',
	'Duck Inn',
	'Criterion Hotel',
	'Duke Of York Hotel',
	'Gawler Arms Hotel',
	'Grange Hotel',
	'Holdfast Hotel',
	'The Bartley Hotel',
	'Maid Of Auckland Hotel',
	'Murray Bridge Golf Club',
	'Republic Norwood',
	'Old Spot Hotel',
	'Prince Albert Hotel',
	'Pier Hotel-Pt Lincoln',
	'Richmond Hotel',
	'Port Lincoln Hotel',
	'Portland Hotel',
	'Rob Roy Hotel',
	'Smithfield Hotel',
	'Southern District Workingmans Club',
	'Grand Tasman Hotel',
	'Willaston Hotel',
	'Hahndorf Old Mill',
	'Wee Willies Tavern',
	'Royal Hotel',
	'Sky Tavern',
	'Lion Hotel',
	'Windsor Hotel',
	'Mccracken Country Club',
	'Tassie Hotel',
	'St Francis Winery Hotel',
	'Griffins Head Hotel',
	'Ambassadors Hotel',
	'Rosemont Hotel',
	'Central Hotel',
	'Kipling’s Restaurant at The Bombay Bicycle Club',
	'The Beach Hotel',
	'Prince Of Wales Hotel',
	'Grays Inn Hotel',
	'Roulettes Tavern',
	'Cremorne Hotel',
	'Elephant & Castle Hotel',
	'Hallett Cove Tavern',
	'Macs Hotel',
	'Hope Inn Hotel',
	'Walkers Arms Hotel',
	'South Lakes Golf Club',
	'Christies Beach Hotel',
	'The Port Club',
	'Flanagans Irish Pub',
	'Empire Hotel',
	'Mawson Lakes Hotel',
	'The Junction',
	'Belgian Beer Cafe',
	'Tavern 540',
	'Richies Tavern',
	'Eagles Function Centre',
	'Jackpots On Pulteney',
	'Carlisle Tavern',
	'Streaky Bay Hotel/Motel',
	'The Little Pub',
	'New Whyalla Hotel',
	'Grenfell 110',
	'Angas Park Hotel',
	'Victoria On The Park',
	'Blue Gums Hotel',
	'Arab Steed Hotel',
	'Aussie Inn ',
	'University Of Adelaide Club',
	'Moseley Hotel',
	'Southern Hotel',
	'Sevenhill Hotel',
	'Rezz Hotel',
	'Wasleys Ridley Arms Hotel',
	'Northern Tavern',
	'Glenelg Footballers Club ',
	'Kentish Arms Hotel',
	'Queens Head Hotel',
	'Stag Hotel',
	'Union Hotel',
	"Harry's Bar",
	'Charminar Restaurant',
	'Gawler & Barossa Jockey Club',
	'Career Employment Group Inc',
	'Southern Bar Gaming Bistro',
	'Grand Junction Tavern',
	'Black Bull Hotel',
	'Angle Vale Tavern',
	'Bridgewater Inn Hotel',
	'Commercial Hotel',
	'Gaza Sports And Community Club',
	'General Havelock Hotel',
	'Hyde Park Tavern',
	'Meadows Hotel',
	'The V Hotel',
	'Commercial Hotel ',
	'Coopers Alehouse ',
	'Bridge Hotel ',
	'Stanley Bridge Tavern',
	'Athelstone Football Club',
	'Crown And Anchor Hotel',
	'The Lighthouse Wharf Hotel',
	'Pooraka Sports and Social Club',
	'Checkside Tavern ',
	'Kings Head Hotel',
	'Copperclub Golf Club ',
	'Mount Gambier Rsl Club',
	'The Propaganda Club ',
	'Travellers Rest Hotel',
	'The Line & Label',
	'Uraidla Hotel',
	'Jones Group- Relief Pool',
	'Cafe Sia Gawler',
	'Barmera Hotel/ Motel',
	'Semaphore Workers Club',
	'Atura Hotel',
	'The Hotel Metropolitan',
	'East End Cellars',
	'The Lodge Hotel',
	'The Goodwood Hotel',
	'Thaxted Park Golf Club',
	'Glenelg Pier Hotel',
	'Glenelg Golf Club',
	'Bedford Group',
	'Lakes Resort',
	'The Barker Hotel',
	'Alberton Hotel',
	'Two Wells Hotel',
	'Berri Golf Club',
	'Cornwall Hotel',
	'Port Hughes Tavern',
	'Taminga Hotel',
	'Berri Resort Hotel',
	'Sporties Tavern',
	'Golden Grove Tavern',
	'Grand Central',
	'Elizabeth Downs Hotel',
	'Playford Tavern',
	'Red Lion Hotel',
	'Eureka Tavern',
	'Salisbury Hotel',
	'Highlander Hotel',
	'Gepps Cross Hotel',
	'Modbury Plaza Hotel',
	'Flagstaff Hill Golf Club',
	'Unley Community Club',
	'Tonsley Hotel',
	'Belair Hotel',
	'Village Tavern',
	'Avoca Hotel',
	'Morphett Arms Hotel',
	'Flagstaff Hotel Darlington',
	'Esplanade Hotel',
	'Brighton Metro Hotel',
	'Club Marion',
	'Norwood Hotel',
	'Bath Hotel',
	'South Adelaide Football Club',
	'Tower Hotel-Ipg',
	'Kensington Hotel',
	'Feathers Hotel',
	'Marryatville Hotel',
	'Paradise Hotel',
	'Arkaba Hotel',
	'Reepham Hotel',
	'Finsbury Hotel',
	'Woodville Hotel',
	'Colonist Tavern',
	'Castle Tavern',
	"Mick O'Sheas Irish Inn",
	'Mansfield Park Hotel',
	'Aberfoyle Hub Tavern',
];
