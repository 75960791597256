import React from "react";

export class Takeover extends React.Component {
  constructor(props) {
    super(props);
    this.state = { input: '' };
    this.onChangeInput = this.onChangeInput.bind(this);
  }

  onChangeInput({ target: { value } }) {
    this.setState({ input: value });
  }

  render() {
    return (
      <div className="takeover">
        <div>
          <h1>Demo mode</h1>

          <label htmlFor="password">
            <span>Enter password</span>

            <input id="password" onChange={this.onChangeInput} name="password" type="password" value={this.state.input} />
          </label>

          <button className="primary" onClick={() => this.props.attemptUnlock(this.state.input)} type="submit">Submit</button>
        </div>
      </div>
    );
  }
}
