import PropTypes from 'prop-types';
import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

class GAListener extends React.Component {
	static contextTypes = {
		router: PropTypes.object,
	};

	constructor(props) {
		super(props);
		ReactGA.initialize('UA-110483195-2');
		ReactPixel.init(
			'272762369884752',
			{},
			{
				autoConfig: true,
			},
		);
	}

	componentDidMount() {
		this.sendPageView(this.context.router.history.location);
		this.context.router.history.listen(this.sendPageView);
	}

	sendPageView(location) {
		ReactGA.set({ page: location.pathname });
		ReactGA.pageview(location.pathname);
		ReactPixel.pageView();
	}

	render() {
		return this.props.children;
	}
}

export default GAListener;
