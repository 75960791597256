import PropTypes from 'prop-types';
import React from 'react';
import styled from 'react-emotion/macro';

import themeStatic from '../../../theme';

const Container = styled.div``;

const LoadingPlaceholder = ({ back }) => <Container data-testid="loading" />;

export default LoadingPlaceholder;

LoadingPlaceholder.propTypes = {
	/** Any colour from the palette defaults to "light" */
	back: PropTypes.oneOf(Object.keys(themeStatic.colors)),
};

LoadingPlaceholder.defaultProps = {
	back: 'primary',
};
